import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const BRANCH_COLUMNS = (handleEdit) => [
  {
    field: 'branchNum',
    headerName: 'Número',
    maxWidth: 100,
    type: 'number',
  },
  {
    field: 'branchName',
    headerName: 'Nombre',
    flex: 8,
    minWidth: 200,
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default BRANCH_COLUMNS;
